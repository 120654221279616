import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { MembershipAvailable, ServiceAvailable } from "./app.component";
import { Time } from "@angular/common";
import { environment } from "src/environments/environment";
import {
  BookingPrerequisites,
  MembershipSelected,
  ServiceSelected,
} from "./booking/booking.component";

@Injectable({ providedIn: "root" })
export class AppService {
  constructor(private http: HttpClient) {}

  getServicesAvailable(centerCode: string): Observable<ServiceAvailable[]> {
    let data = {
      hashcode: centerCode,
    };

    return of([
      {
        id: 1,
        name: "Swimming 1 Hour",
        maxCost: 1000,
        durationInMinute: 60,
      },
      {
        id: 1,
        name: "Swimming 2 Hour",
        maxCost: 1800,
        durationInMinute: 120,
      },
    ]);

    // return this.http
    //   .post<{ data: ServiceAvailable[] }>(
    //     environment.dbURL + '/appointments-p/list-of-available-services',
    //     data
    //   )
    //   .pipe(map((ob) => ob.data));
  }

  getMembershipsAvailable(
    centerCode: string
  ): Observable<MembershipAvailable[]> {
    let data = {
      hashcode: centerCode,
    };

    return of([
      {
        id: 1,
        name: "Swimming 1 Month",
        maxCost: 4000,
        months: 1,
        days: 0,
      },
      {
        id: 2,
        name: "Swimming 2 Month",
        maxCost: 7000,
        months: 2,
        days: 0,
      },
      {
        id: 3,
        name: "Swimming 3 Month",
        maxCost: 10000,
        months: 3,
        days: 0,
      },
    ]);

    // return this.http
    //   .post<{ data: ServiceAvailable[] }>(
    //     environment.dbURL + '/appointments-p/list-of-available-services',
    //     data
    //   )
    //   .pipe(map((ob) => ob.data));
  }

  getBookingPrerequistics(
    centerCode: string
  ): Observable<BookingPrerequisites> {
    // return of({
    //   salonName: 'The Hive - Hair & Beauty Salon',
    //   salonAddress: 'Mazgaon, Mumbai',
    //   salonContact: '7506786110',
    //   logoPath: null,
    //   priceVisibility: true,
    //   thankYouMessage:
    //     'You will soon receive a comfimation for your appointment',
    //   enableWhatsAppButton: true,
    //   enableCallButton: true,
    //   whatsAppNumber: '919063371408',
    //   callingNumber: '919063371408',
    // });

    let data = {
      hashCode: centerCode,
    };

    return this.http
      .post<{ data: BookingPrerequisites }>(
        environment.dbURL + "/services-finance-p/add-bill-prerequisites",
        data
      )
      .pipe(
        map((ob) => {
          console.log(ob);
          return ob.data;
        })
      );
  }

  requestAppointment(
    hashCode: string,
    userName: string,
    userContact: string,
    masterServiceId: number,
    durationInMinutes: number,
    quantity: number,
    actualAmount: number,
    discountedAmount: number,
    totalAmount: number,
    datetimeFrom: Date,
    datetimeTo: Date,
    amount: number
  ): Observable<any> {
    let data = {
      hashCode,
      userName,
      userContact,
      masterServiceId,
      durationInMinutes,
      quantity,
      actualAmount,
      discountedAmount,
      totalAmount,
      datetimeFrom,
      datetimeTo,
      amount,
    };

    return this.http.post<{ data: any }>(
      environment.dbURL + "/appointments-p/add-appointment",
      data
    );
  }

  createBill(
    hashCode: string,
    userName: string,
    userContact: string,
    listOfServices: ServiceSelected[],
    listOfPayments: any[],
    listOfMemberships: MembershipSelected[],
    totalAmount: number,
    discountedAmount: number,
    paidAmount: number,
    balanceAmount: number,
    balancePaymentDate: Date,
    isTax: boolean,
    baseAmount: number,
    taxAmount: number,
    taxPercentage: number,
    taxType: string,
    isTaxInclusive: boolean
  ): Observable<any> {
    let data = {
      hashCode,
      userName,
      userContact,
      listOfServices,
      listOfPayments,
      listOfMemberships,
      totalAmount,
      discountedAmount,
      paidAmount,
      balanceAmount,
      balancePaymentDate,
      isTax,
      baseAmount,
      taxAmount,
      taxPercentage,
      taxType,
      isTaxInclusive,
    };

    return this.http.post<{ data: any }>(
      environment.dbURL + "/services-finance-p/add-bill",
      data
    );
  }
}
