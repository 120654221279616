import { Component } from "@angular/core";
import { AppService } from "./app.service";
import { error } from "@angular/compiler/src/util";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";

export interface ServiceAvailable {
  id: number;
  name: string;
  maxCost: number;
  durationInMinute: number;
}

export interface MembershipAvailable {
  id: number;
  name: string;
  maxCost: number;
  months: number;
  days: number;
}

export enum TAX_TYPES {
  NONE = "NONE",
  CGST_SGST = "CGST_SGST",
  IGST = "IGST",
  VAT = "VAT",
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor() {}
}
