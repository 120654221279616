import { Component } from "@angular/core";
import { AppService } from "../app.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import { MembershipAvailable, TAX_TYPES } from "../app.component";

export interface ServiceAvailable {
  id: number;
  name: string;
  maxCost: number;
  durationInMinutes: number;
}

export interface ServiceSelected {
  masterServiceId: number;
  actualAmount: number;
  discountedAmount: number;
  quantity: number;
  totalAmount: number;
}

export interface Membership {
  id: number;
  name: string;
  maxCost: number;
  durationInMinutes: number;
}

export interface MembershipSelected {
  masterMembershipId: number;
  actualAmount: number;
  discountedAmount: number;
  startDate: Date;
  endDate: Date;
}

export interface BookingPrerequisites {
  centerName: string;
  CenterAddress: string;
  centerContact: string;
  logoPath: string;
  masterMembershipList: {
    id: number;
    membership: string;
    months: number;
    days: number;
  }[];
  masterServiceList: {
    id: number;
    name: string;
    maxCost: number;
    minCost: number;
  }[];
}

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
})
export class BookingComponent {
  saleTypeForm: FormGroup;
  contactForm: FormGroup;
  listOfServicesAvailable: any[] = [];
  listOfMembershipAvailable: any[] = [];
  selectedAvailableService: ServiceSelected = null;
  selectedMembership: MembershipSelected = null;
  inProcess = false;

  // end

  step = 1;

  appointmentPrerequistics: BookingPrerequisites = null;
  selectedTime: string = null;
  centerCode = null;

  form: FormGroup = null;
  //contactForm: FormGroup = null;

  contactFormErrorMessage: string = null;
  loading: boolean = false;
  public logoPath = "../../../assets/img/logo/your-logo-here.png";

  constructor(public appService: AppService, public route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params["hashCode"]) {
        this.centerCode = params["hashCode"];
      }
    });

    this.saleTypeForm = new FormGroup({
      saleType: new FormControl("service"),
    });

    this.form = new FormGroup({
      date: new FormControl(moment(new Date()).format("yyyy-MM-DD"), [
        Validators.required,
      ]),
    });

    this.contactForm = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30),
      ]),
      contact: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
    });

    this.loading = true;
    this.appService.getBookingPrerequistics(this.centerCode).subscribe(
      (res) => {
        this.loading = false;
        this.appointmentPrerequistics = res;

        this.listOfServicesAvailable = res.masterServiceList;
        this.listOfMembershipAvailable = res.masterMembershipList;
      },
      (error) => {
        this.loading = false;
      }
    );

    this.saleTypeForm.valueChanges.subscribe((res) => {});
  }

  onSelectService(serviceAvailable: ServiceAvailable) {
    this.selectedAvailableService = {
      masterServiceId: serviceAvailable.id,
      discountedAmount: serviceAvailable.maxCost,
      actualAmount: serviceAvailable.maxCost,
      quantity: 1,
      totalAmount: serviceAvailable.maxCost,
    };
    this.step = 2;
  }

  onSelectMembership(membershipAvailable: MembershipAvailable) {
    this.selectedMembership = {
      masterMembershipId: membershipAvailable.id,
      actualAmount: membershipAvailable.maxCost,
      discountedAmount: membershipAvailable.maxCost,
      startDate: new Date(),
      endDate: moment(new Date())
        .add(membershipAvailable.months)
        .add(membershipAvailable.days)
        .toDate(),
    };
    this.step = 2;
  }

  onBackToStep1() {
    this.step = 1;
  }

  onStep2Submit() {
    if (this.contactForm.invalid) {
      if (this.contactForm.controls["name"].invalid) {
        this.contactFormErrorMessage = "Incorrect name";
      } else if (this.contactForm.controls["contact"].invalid) {
        this.contactFormErrorMessage = "Incorrect mobile number";
      }
      return;
    }

    this.step = 3;
  }

  onBackToStep2() {
    this.step = 2;
  }

  onSelectTimeSlot(selectedTime: string) {
    if (this.form.invalid) {
      alert("Select date");
      return;
    }

    this.selectedTime = selectedTime;

    this.onStep2Submit();
  }

  onSubmitStep3() {
    if (this.form.invalid) {
      alert("Please select date");
      return;
    } else if (this.contactForm.invalid) {
      if (this.contactForm.controls["name"].invalid) {
        this.contactFormErrorMessage = "Incorrect name";
      } else if (this.contactForm.controls["contact"].invalid) {
        this.contactFormErrorMessage = "Incorrect mobile number";
      }
      return;
    }

    let name: string = this.contactForm.value.name;
    let contact: string = this.contactForm.value.contact;
    let listOfServices: ServiceSelected[] = this.selectedAvailableService
      ? [this.selectedAvailableService]
      : [];
    let listOfMemberships = this.selectedMembership
      ? [this.selectedMembership]
      : [];
    let totalAmount: number =
      listOfServices.length > 0
        ? listOfServices.map((ob) => ob.totalAmount).reduce((a, b) => a + b)
        : 0 + listOfMemberships.length > 0
        ? listOfMemberships
            .map((ob) => ob.discountedAmount)
            .reduce((a, b) => a + b)
        : 0;
    let discountedAmount: number =
      listOfServices.length > 0
        ? listOfServices.map((ob) => ob.totalAmount).reduce((a, b) => a + b)
        : 0 + listOfMemberships.length > 0
        ? listOfMemberships
            .map((ob) => ob.discountedAmount)
            .reduce((a, b) => a + b)
        : 0;
    let isTax: boolean = true;
    let payingAmount: number = discountedAmount;
    let paymentModeId: number = 1;
    let balanceAmount: number = 0;
    let balancePaymentDate: Date = null;

    let baseAmount: number = discountedAmount;
    let taxAmount: number = 0;
    let taxPercentage: number = 0;
    let taxType: TAX_TYPES = TAX_TYPES.CGST_SGST;
    let isTaxInclusive: boolean = true;

    this.inProcess = true;
    this.appService
      .createBill(
        this.centerCode,
        name,
        contact,
        listOfServices,
        [
          {
            paymentModeId: paymentModeId,
            amount: payingAmount,
          },
        ],
        listOfMemberships,
        totalAmount,
        discountedAmount,
        payingAmount,
        balanceAmount,
        balancePaymentDate,
        isTax,
        baseAmount,
        taxAmount,
        taxPercentage,
        taxType,
        isTaxInclusive
      )
      .subscribe(
        (res) => {
          this.step = 4;
          this.inProcess = false;
        },
        (error) => {
          alert("Opps... Somnething went wrong");
          this.inProcess = false;
        }
      );
  }

  onBookAnother() {
    location.reload();
  }
}
