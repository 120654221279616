<div *ngIf="loading">Loading...</div>

<div *ngIf="!loading">
  <div *ngIf="appointmentPrerequistics">
    <div [hidden]="!(step === 1)">
      <div>
        <img
          src="../../assets/img/client.jpeg"
          style="width: 100%; margin-bottom: 10px"
        />

        <table style="width: 100%">
          <tr>
            <td style="width: 100%" style="text-align: center">
              <img
                src="../../assets/img/logo/client.png"
                style="width: 200px"
              />
              <br />
            </td>
          </tr>
          <tr>
            <td style="width: 100%" style="text-align: center">
              <p
                style="
                  font-style: italic;
                  font-size: smaller;
                  margin: 0px;
                  color: gray;
                "
              >
                HUDA Gymkhana Club, Sector-29, Gurugram <br />
                91 9811820166, +91 9643448283
              </p>
            </td>
          </tr>
        </table>
        <br />
        <h2
          style="margin-bottom: 10px; text-align: center; margin-top: 0px"
          *ngIf="step !== 4"
        >
          Book Online
        </h2>
      </div>
      <div class="p-2">
        <form [formGroup]="saleTypeForm">
          <table style="width: 100%">
            <tr>
              <td>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="saleType"
                    value="service"
                    formControlName="saleType"
                  />
                  <label class="form-check-label"> Services </label>
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="saleType"
                    value="membership"
                    formControlName="saleType"
                  />
                  <label class="form-check-label"> Memberships </label>
                </div>
              </td>
            </tr>
          </table>
        </form>
        <br />

        <div *ngIf="saleTypeForm.value.saleType === 'service'">
          <ol class="list-group" *ngIf="listOfServicesAvailable?.length > 0">
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let ob of listOfServicesAvailable"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">{{ ob.name }}</div>
                <span> Rs.{{ ob.maxCost }}/- </span>
              </div>

              <button class="btn btn-primary" (click)="onSelectService(ob)">
                Book
              </button>
            </li>
          </ol>

          <div *ngIf="!(listOfServicesAvailable?.length > 0)">
            Sorry, no services are available right now.
          </div>
        </div>

        <div *ngIf="saleTypeForm.value.saleType === 'membership'">
          <ol class="list-group" *ngIf="listOfMembershipAvailable?.length > 0">
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let ob of listOfMembershipAvailable"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">{{ ob.membership }}</div>
                <span> Rs.{{ ob.maxCost }}/- </span>
              </div>

              <button class="btn btn-primary" (click)="onSelectMembership(ob)">
                Book
              </button>
            </li>
          </ol>

          <div *ngIf="!(listOfMembershipAvailable?.length > 0)">
            Sorry, no membership are available right now.
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="!(step === 2)" class="p-2">
      <table style="width: 100%">
        <tr>
          <td style="width: 100%" style="text-align: center">
            <img src="../../assets/img/logo/client.png" style="width: 200px" />
            <br />
          </td>
        </tr>
        <tr>
          <td style="width: 100%" style="text-align: center">
            <p
              style="
                font-style: italic;
                font-size: smaller;
                margin: 0px;
                color: gray;
              "
            >
              HUDA Gymkhana Club, Sector-29, Gurugram <br />
              91 9811820166, +91 9643448283
            </p>
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />

      <form [formGroup]="contactForm">
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            formControlName="name"
          />
        </div>

        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Mobile number"
            formControlName="contact"
            minlength="10"
            maxlength="10"
          />
        </div>

        <div class="mb-3" *ngIf="contactFormErrorMessage">
          <div class="alert alert-danger" role="alert">
            {{ contactFormErrorMessage }}
          </div>
        </div>

        <div class="mb-3 form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="flexCheckDefault">
            I accept <a href="https://google.com"> Term & condition</a>
          </label>
        </div>

        <div class="mb-3">
          <button
            class="btn btn-primary"
            style="width: 100%"
            (click)="onStep2Submit()"
          >
            Book & Pay Online
          </button>
        </div>
      </form>
      <br />

      <!-- <table style="width: 100%">
        <tr>
          <td style="width: 70%">
            <button class="btn btn-light" (click)="onBackToStep1()">
              < Back
            </button>
          </td>
          <td style="width: 30%; text-align: right">
            <button class="btn btn-light" (click)="onStep2Submit()">
              Next >
            </button>
          </td>
        </tr>
      </table> -->
    </div>

    <div [hidden]="!(step === 3)" class="p-2">
      <br />
      <br />
      <img
        src="https://e-kendra.co.in/wp-content/uploads/2021/12/upi-pay.png"
        style="width: 100%"
      />

      <br />
      <br />

      <!-- <table style="width: 100%">
          <tr>
            <td style="width: 70%">
              <button class="btn btn-light" (click)="onBackToStep2()">
                < Back
              </button>
            </td>
            <td style="width: 30%; text-align: right">
              <button class="btn btn-light" (click)="onSubmitStep3()">
                Next >
              </button>
            </td>
          </tr>
        </table> -->

      <button
        class="btn btn-primary"
        style="width: 100%"
        (click)="onSubmitStep3()"
      >
        {{ inProcess ? "PLEASE WAIT..." : "PAY" }}
      </button>
    </div>

    <div [hidden]="!(step === 4)" style="text-align: center">
      <br />
      <br />
      <img
        src="https://www.lappymaker.com/images/greentick-unscreen.gif"
        style="width: 100%"
      />
      <br />

      <div class="p-2">
        <h4 style="font-weight: lighter">Thank you !</h4>

        <p>Your order is #76153, show this at reception and check in</p>

        <br />

        <br />
        <br />

        <button
          (click)="onBookAnother()"
          class="btn btn-primary"
          style="width: 100%"
        >
          Book again
        </button>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />

    <img
      src="../../assets/img/swim.png"
      style="width: 100%; position: fixed; bottom: 0px"
    />
  </div>

  <div *ngIf="!appointmentPrerequistics">
    Looks like link is invalid. Please contact your salon
  </div>
</div>
